<script>
import { useRouter } from 'vue-router';
import Table from '@/components/table/Table.vue';
import Button from '@/components/Button.vue';
import { useQuery } from '@urql/vue';
import { computed } from 'vue';
import GetTreatments from '../queries/GetTreatments';


export default {
  components: {
    Table,
    Button,
  },
  setup() {
    const router = useRouter();
    const tableHeaders = [
      {
        label: 'Name',
        key: 'name',
        width: '3fr',
      },
      {
        label: 'Short Name',
        key: 'shortName',
        width: '2fr',
      },
      {
        label: 'Treatment Length',
        key: 'treatmentLength',
        width: '2fr',
      },
      {
        label: '',
        type: 'button',
        key: 'edit',
        buttonText: 'Edit',
        emitEvent: 'rowclick',
      },
    ];

    const treatmentsResult = useQuery({
      query: GetTreatments,
    });

    const tableData = computed(() => {
      if (treatmentsResult.data.value && treatmentsResult.data.value.treatments) {
        return treatmentsResult.data.value.treatments;
      }
      return [];
    });

    const onRowClick = (row) => {
      router.push({ name: 'admin-treatments-edit', params: { id: row.id } }, () => {});
    };

    return {
      router,
      tableHeaders,
      tableData,
      onRowClick,
    };
  },
};
</script>
<template>
  <div class="container">
    <div class="header">
      <h1>Treatment Plans</h1>
      <Button @click="router.push({ name: 'admin-treatments-create'}, () => {})">
        New Treatment Plan
      </Button>
    </div>
    <Table :headers="tableHeaders" :data="tableData" @rowclick="onRowClick"/>
  </div>
</template>
<style lang="scss" scoped>
  .container {
    max-width: $containerWidth;
    margin: 0 auto;
    padding: 1rem;
    padding-top: 4rem;
  }
  h1 {
    @include header;
    text-align: left;
    padding: 1rem 0;
    font-size: 1.5rem;
    flex: 1;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
