import { gql } from 'urql/core';

export default gql`
query getTreatments {
  treatments {
    id
    name
    shortName
    treatmentLength
    preMedOptions {
      id
      medication {
        id
        name
        medicationType {
          id
          name
        }
        color
        color2
        pillDescription
        instructions
        otherInfo
        pillAppearance
        pillAppearanceThumb
      }
      daysInterval
      daysRange
      specialInstructions
      dosageType
      length
      givenByNurse
    }
    antiviralTreatment {
      id
      medication {
        id
        name
        medicationType {
          id
          name
        }
        color
        color2
        pillDescription
        instructions
        otherInfo
        pillAppearance
        pillAppearanceThumb
      }
      daysInterval
      daysRange
      specialInstructions
      dosageType
      length
      givenByNurse
    }
    bloodClotPrevention {
      id
      medication {
        id
        name
        medicationType {
          id
          name
        }
        color
        color2
        pillDescription
        instructions
        otherInfo
        pillAppearance
        pillAppearanceThumb
      }
      daysInterval
      daysRange
      specialInstructions
      dosageType
      length
      givenByNurse
    }
    rulesForAllCycles {
      id
      medication {
        id
        name
        medicationType {
          id
          name
        }
        color
        color2
        pillDescription
        instructions
        otherInfo
        pillAppearance
        pillAppearanceThumb
      }
      daysInterval
      daysRange
      specialInstructions
      dosageType
      length
      givenByNurse
    }
    cycles {
      id
      name
      medicationPlans {
        id
        medication {
          id
          name
          medicationType {
            id
            name
          }
          color
          color2
          pillDescription
          instructions
          otherInfo
          pillAppearance
          pillAppearanceThumb
        }
        daysInterval
        daysRange
        specialInstructions
        dosageType
        length
        givenByNurse
      }
    }
  }
}
`;
